export enum actionTypes {
  TOGGLE_LOADER = 'common/TOGGLE_LOADER',
}

export interface toggleLoaderAction {
  type: typeof actionTypes.TOGGLE_LOADER
  payload?: boolean
}

export type CommonAction = toggleLoaderAction

export const toggleLoader = (isVisible = undefined) => ({
  type: actionTypes.TOGGLE_LOADER,
  payload: isVisible || false,
})
